import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RegistrationService} from "../../services/wabel-client/services/registration.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {MemberService} from "../../services/wabel-client/services/member.service";
import {UtilsService} from "../../services/utils.service";
import {LocalStorageService} from "ngx-webstorage";
import {environment} from "../../../environments/environment";
import {Company} from "../../services/wabel-client/entities/company";

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

    needl_full_url: string = environment.needl_url;
    user: any;
    @Input() type: string = 'buyer';
    @Input() prefix: string = '';
    @Input() isStaticPage: boolean = false;
    @Output() close: EventEmitter<string> = new EventEmitter<string>();

    @Input() isLogin: boolean = false;
    @Input() isModal: boolean = false;

    typeOfCie: number = 6;
    screenType: string = 'login';
    flashMessage: any = null;
    asynchronousButtonState: string = 'before';
    asynchronousButtonStateSignUp: string = 'before';
    showErrorSignUpEmailAlreadyExists: boolean = false;
    isEmail: boolean = true;
    signUpFormAlreadyClicked: boolean = false;
    destination: string;
    errorAfterSubmit: string = null;
    formCompleted: boolean = true;

    @ViewChild('signInOrSignUpForm', { static: true }) signInOrSignUpForm: NgForm;

    constructor(
        private registrationService: RegistrationService,
        private router: Router,
        private route: ActivatedRoute,
        private memberService: MemberService,
        private utilsService: UtilsService,
        private localStorageService: LocalStorageService,
    ) {
        this.user = {
            username: '',
            password: ''
        }
    }

    ngOnInit() {
        if (!this.isModal) {
            window.scrollTo(0, 0);
        }
        this.destination = this.router.url;
        this.rerouteDestination();
        this.route.queryParams.subscribe(value => {
            if (value.hasOwnProperty('destination')) {
                let urlParsed = this.utilsService.parseURL(value.destination);
                let urlCurrent = this.utilsService.parseURL(this.router.url);

                if (this.utilsService.strEndsWith(urlParsed.host, 'needl.co')) {
                    // If same host, we can use the pathname
                    if (urlParsed.host === urlCurrent.host) {
                        this.destination = urlParsed.pathname;
                    } else {
                        // If not, we now it's a subdomain, so we trust the destination
                        this.destination = value.destination;
                    }
                } else {
                    // Otherwise, the host is different, we set a default destination
                    this.destination = '/home';
                }
                this.rerouteDestination();
            }
        });
        if (this.isLogin) {
            this.screenType = 'login';
        } else {
            this.screenType = 'sign-up';
        }
    }

    private rerouteDestination() {
        if (this.destination === '/user/login') {
            this.destination = '/home';
        } else if (decodeURIComponent(this.destination).indexOf('/sign-up/') !== -1) {
            this.destination = '/profile/user';
        }
    }

    typeOfCompanyChanged(event) {
        if (event === 6) {
            this.type = 'buyer';
        } else if (event === 3) {
            this.type = 'importer';
        } else if (event === 2) {
            this.type = 'manufacturer';
        } else if (event === 18) {
            this.type = 'package';
        }else if (event === 66) {
            this.type = 'co-packer';
        }else {
            this.type = 'other';
        }
    }

    goToSignUpForm() {

        if (this.asynchronousButtonStateSignUp == 'before' && this.user.username && this.user.username != '') {
            this.signUpFormAlreadyClicked = true;
            if (this.isEmail) {
                this.asynchronousButtonStateSignUp = 'loading';
                this.utilsService.checkIfEmailAlreadyExists(this.user.username).subscribe(data => {
                        if (!data) return;
                        this.errorAfterSubmit = null;
                        if (data['valid'] === true) {
                            this.asynchronousButtonStateSignUp = 'before';
                            this.close.emit();
                            this.registrationService.setEmailAfterRegistrationPopup(this.user.username);
                            this.router.navigate(['/sign-up', this.type], {queryParams: {destination: this.destination}});
                        } else {
                            this.asynchronousButtonStateSignUp = 'before';
                            this.showErrorSignUpEmailAlreadyExists = true;
                        }
                    },
                    error => {
                        this.asynchronousButtonStateSignUp = 'before';
                        this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                    });
            }
        }
    }

    signInProcess() {
        if (!this.user.username) this.errorAfterSubmit = 'Email field is required.';
        if (!this.user.password.length) this.errorAfterSubmit = 'Password field is required.';
        if (!this.user.username && !this.user.password) this.errorAfterSubmit = 'Username and password are required.';
        if (!this.user.username || !this.user.password.length) {
            return;
        }
        if (this.asynchronousButtonState == 'before') {
            this.asynchronousButtonState = 'loading';
            this.errorAfterSubmit = null;
            this.memberService.login(this.user.username, this.user.password).subscribe((data) => {
                if (!data) return;
                this.localStorageService.clear();
                if (data.idmember) {
                    if (!this.isModal) {
                        this.asynchronousButtonState = 'after';
                        if (data.joinMode == 'invite_from_zoho' && (this.isForcedToReRegisterByType(data.company))  && !data.signupFinished){
                            this.registrationService.setMemberForZohoRegistration(data);
                            this.router.navigate(['/sign-up', data.company.getTypeOfCompany()]);
                        }else{
                            window.location.href = this.destination;
                        }
                    } else {
                        if (data.joinMode == 'invite_from_zoho' && (this.isForcedToReRegisterByType(data.company)) && !data.signupFinished){
                            this.router.navigate(['/sign-up', data.company.getTypeOfCompany()]);
                            this.registrationService.setMemberForZohoRegistration(data);
                        }else{
                            location.reload();
                        }
                    }

                }
            }, (error) => {
                this.errorAfterSubmit = this.utilsService.detectErrorMessages(error).join('. ');
                this.asynchronousButtonState = 'before';
            });
        }
    }

    isForcedToReRegisterByType(company:Company){
        if (company.isImporter()
            || company.isManufacturer()
            || company.isBuyer()
            || company.isPackagingSupplier()){
            return true
        }
        return false;
    }
    submitLoginForm() {
        this.signInOrSignUpForm.ngSubmit.emit();
    }

    passwordLostFormSubmitted() {
        if (this.asynchronousButtonState === 'loading' || !this.user.username || !this.isEmail) {
            return;
        }
        this.flashMessage = null;
        this.asynchronousButtonState = 'loading';
        this.memberService.sendEmailPasswordRecovery(this.user.username).subscribe(member => {
            if (member === null) {
                this.flashMessage = {
                    type: 'success',
                    title: 'Password recovery email sent',
                    message: 'If an account was found with this email, a reset link will be sent shortly'
                };
            } else {
                this.user.username = '';
                this.flashMessage = {
                    type: 'success',
                    title: 'Password recovery email sent',
                    message: 'If an account was found with this email, a reset link will be sent shortly'
                };
            }
            this.asynchronousButtonState = 'before';
        }, (error) => {
            this.flashMessage = {
                type: 'error',
                title: 'Error',
                message: this.utilsService.detectErrorMessages(error).join('. ')
            };
            this.asynchronousButtonState = 'before';
        });
    }

    validatorEmail(value) {
        this.isEmail = value;
    }
}
