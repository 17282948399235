import gql from "graphql-tag";
import {EventOrganizerGraphql} from "./event_organizer.graphql";
import {TagTypeService} from "../services/tag_type.service";
import {TagTypeGraphql} from "./tag_type.graphql";

export class RegistrationGraphql {
    static fragments = {
        light: gql`
            fragment lightRegistration on CertainEventRegistration {
                idcertaineventregistration,
                event {
                    fullname,
                    eventCode,
                    catalogReady,
                    selectionReady,
                },
                member {
                    idmember,
                    fullname,
                    email,
                    status,
                    zohoId,
                    company {
                        idcompany,
                        name,
                        type,
                        status,
                        company_type_machine_name,
                        zohoId
                    }
                }
                checkedIn,
                availabilityStart,
                availabilityEnd,
                availabilities {
                    items {
                        availabilityStart,
                        availabilityEnd
                    }
                }
            }
        `,
        full: gql`
            fragment fullRegistration on CertainEventRegistration {
                idcertaineventregistration,
                event {
                    fullname,
                    eventCode,
                    catalogReady,
                    selectionReady,
                },
                member {
                    idmember,
                    firstname,
                    name,
                    fullname,
                    email,
                    zohoId,
                    logoUrl(size: "123")
                    company {
                        idcompany,
                        name,
                        type,
                        status,
                        company_type_machine_name,
                        zohoId,
                        categories: tags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                            items {
                                ...fullTag
                            }
                        }
                        products(published: true) {
                            iduserproduct
                        }
                        parentCompany {
                            idcompany
                        }
                    }
                }
                selectedBy {
                    idmember,
                    fullname
                }
                excludedBy {
                    idmember,
                    fullname
                }
                selections: meetingPreferences(status: ["selected"]) {
                    items {
                        company {
                            idcompany,
                            name
                        }
                    }
                }
                exclusion {
                    idcompany,
                    name
                }
                buyingScope: buyingScopeTags(tagTypeInternalName: "${TagTypeService.CATEGORY_INTERNAL_NAME}") {
                    items {
                        ...tag
                    }
                }
                hotel,
                room,
                nights {
                    date
                }
                autolog,
                summits {
                    summitCode
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
            ${TagTypeGraphql.fragments.tag}
        `,
        list: gql`
            fragment listRegistration on CertainEventRegistrationInterface {
                idcertaineventregistration,
                checkedIn,
                availabilityStart,
                availabilityEnd,
                dateChecking,
                bestBadgeLabel,
                meetingRoom,
                noDiary,
                event {
                    eventCode
                },
                member {
                    idmember,
                    fullname,
                    email,
                    status,
                    zohoId,
                    timezone,
                    company {
                        idcompany,
                        name,
                        type,
                        isRetailer,
                        isSupplier,
                        company_type_machine_name,
                        consultant {
                            logoUrl(size: "123")
                            fullname
                            mobile
                            email
                        }
                    }
                }
                availabilities {
                    items {
                        availabilityStart,
                        availabilityEnd
                    }
                }
                summits {
                    summitCode,
                    name
                }
                badgeUrl
                scheduleUrl
                badgeScheduleUrl
            }
        `,
        qrCode: gql`
            fragment qrRegistration on CertainEventRegistration {

                idcertaineventregistration,
                registrationCode,
                autolog,
                selectionToolUrl,
                messageByEmail,
                messageBySms,
                meetingRoom,
                dateChecking,
                availabilityStart,
                availabilityEnd,
                availabilities {
                    items {
                        availabilityStart,
                        availabilityEnd,
                    }
                }
                event {
                    eventCode
                },
                nights {
                    date
                },
                room,
                hotel,
                member {
                    zohoId
                    idmember,
                    fullname,
                    firstname,
                    name,
                    email,
                    mobile_event,
                    company {
                        name,
                        consultant {
                            logoUrl(size: "123")
                            fullname
                            mobile
                            email
                        }
                    }
                },
            }
        `,
        registrationMeetingList: gql`
            fragment registrationMeetingList on CertainEventRegistrationInterface {
                idcertaineventregistration
                meetings(status: ["ok", "updated"]) {
                    items {
                        idmeeting
                        startDate
                        endDate
                        status
                        buyerPresent
                        supplierPresent
                        hasBeenRatedFromMyCompany
                        memberOppositeRegistration {
                            member {
                                fullname
                                jobtitle
                                company {
                                    idcompany
                                    hasVideoMeetingRequestWithMe
                                    hasVideoMeetingWithMe
                                    name
                                    logoUrl(size:"123")
                                    urlname
                                    products(published: true, limit: 3) {
                                        logoUrl(size:"123")
                                    }
                                }
                            }
                        }
                        myRatings {
                            items {
                                idmeetingrating
                                ratingInternalName
                                rating
                            }
                        }
                        relation {
                            commentGiven
                        }
                        memberBuyer {
                            idmember
                            fullname
                        }
                        memberSupplier {
                            idmember
                            fullname
                        }
                        companyBuyer {
                            idcompany
                        }
                        companySupplier {
                            idcompany
                        }
                    }
                    count
                }
            }
        `
    };

    static queries = {
        myRegistration: gql`
            query myEventLobbyResolver($eventCode: String!) {
                myRegistration(eventCode: $eventCode) {
                    idcertaineventregistration
                    hotel
                    nightsDates
                    badgeUrl
                    noDiary
                    permissions
                    wantSamples
                    dateSelectionFinished
                    eventMeetingConfirmations {
                        items {
                            ideventmeetingconfirmation
                            meeting {
                                idmeeting
                            }
                            companyTarget {
                                idcompany
                            }
                        }
                    }
                    selections: meetingPreferences(status: ["selected"]) {
                        items {
                            status
                            company {
                                idcompany
                                name
                            }
                        }
                    }
                    meetingsToReschedule: meetings(status: ["to_reschedule", "external_rescheduled"]) {
                        items {
                            idmeeting
                            startDate
                            memberOppositeRegistration {
                                member {
                                    company {
                                        hasVideoMeetingRequestWithMe
                                        hasVideoMeetingWithMe
                                        idcompany
                                    }
                                }
                            }
                        }
                    }

                    meetings(status: ["ok", "updated"]) {
                        items {
                            idmeeting
                            startDate
                            endDate
                            status
                            buyerPresent
                            supplierPresent
                            hasBeenRatedFromMyCompany
                            memberOppositeRegistration {
                                member {
                                    fullname
                                    jobtitle
                                    company {
                                        idcompany
                                        hasVideoMeetingRequestWithMe
                                        hasVideoMeetingWithMe
                                        name
                                        logoUrl(size:"123")
                                        urlname
                                        products(published: true, limit: 3) {
                                            logoUrl(size:"123")
                                        }
                                    }
                                }
                            }
                            myRatings {
                                items {
                                    idmeetingrating
                                    ratingInternalName
                                    rating
                                }
                            }
                            relation {
                                commentGiven
                            }
                            memberBuyer {
                                idmember
                                fullname
                            }
                            memberSupplier {
                                idmember
                                fullname
                            }
                            companyBuyer {
                                idcompany
                            }
                            companySupplier {
                                idcompany
                            }
                        }
                        count
                    }
                    meetingsWithSampleRequests: meetings(status: ["ok", "updated"], withSampleRequestOnly: true) {
                        items {
                            idmeeting
                            startDate
                            sampleRequest {
                                status
                                thread {
                                    idthread
                                }
                                address {
                                    firstName
                                    lastName
                                    addressLine1
                                    addressLine2
                                    city
                                    zipcode
                                    phone
                                    country {
                                        name
                                    }
                                }
                            }
                            threadBetweenCompanySupplierAndMemberBuyer {
                                idthread
                            }
                            memberOppositeRegistration {
                                member {
                                    idmember
                                    fullname
                                    company {
                                        idcompany
                                        name
                                        urlname
                                    }
                                }
                            }
                        }
                    }
                    meetingsWithRatingsFromOthers: meetings(status: ["ok", "updated"], withRatingsFromOthers: true) {
                        items {
                            idmeeting
                            startDate
                            companyBuyer {
                                idcompany
                            }
                            companySupplier {
                                idcompany
                            }
                            memberOppositeRegistration {
                                member {
                                    idmember
                                    fullname
                                    email
                                    company {
                                        idcompany
                                        name
                                        urlname
                                    }
                                }
                            }
                            myRatings {
                                items {
                                    idmeetingrating
                                }
                            }
                            othersRatings {
                                items {
                                    idmeetingrating
                                    member {
                                        idmember
                                        fullname
                                        email
                                    }
                                    ratingInternalName
                                    rating
                                    threadBetweenCompanySupplierAndMemberBuyer {
                                        idthread
                                    }
                                }
                            }
                        }
                    }
                    summitRegistrations {
                        items {
                            summit {
                                bannerUrl
                                summitCode
                                name
                                marketingPageUrl
                            }
                        }
                    }
                    eventWorkshopPreferences {
                        items {
                            eventworkshop {
                                ideventworkshop
                                title
                                displayInSelectionTool
                                member {
                                    fullname
                                    jobtitle
                                }
                                expert {
                                    company {
                                        name
                                    }
                                }
                                startDate
                                endDate
                                conferenceType
                                idZoomWebinar
                                externalUrl
                                video {
                                    hostId
                                    description
                                }
                            }
                        }
                    }
                    certainevent {
                        eventCode
                        name
                        dates
                        startDate
                        ispackaging
                        endDate
                        location
                        badgeTemplateUrl
                        catalogReady
                        selectionReady
                        agendaReady
                        ratingReady
                        ratingReadyForEssentialAndPremiumOnly
                        isOnline
                        jitsiFallback
                        allowSampleRequests
                        helpdeskActivated
                        companySubmissionOpen
                        meetingConfirmationsOpened
                        removeMeetingAllowed
                        adminNotificationCc
                        isFinished
                        isStarted
                        meetingNumber
                        meetingReschedulerOpened
                        backgroundImageUrl
                        meetingDuration
                        forceOpenMeetingInNewTab
                        eventOrganizer {
                            name
                            urlname
                            mainColor
                            helpdeskKey
                        }
                        summits {
                            items {
                                name
                                summitCode
                            }
                        }
                        eventFiles {
                            url
                            downloadLink
                            file {
                                description
                                dateCreated
                            }
                        }
                        workshops {
                            ideventworkshop
                            title
                            displayInSelectionTool
                            description
                            member {
                                fullname
                                jobtitle
                            }
                            expert {
                                acceptsMeetings
                                company {
                                    idcompany,
                                    name,
                                    logoUrl(size: "123"),
                                }
                            }
                            startDate
                            endDate
                            conferenceType
                            idZoomWebinar
                            externalUrl
                            video {
                                hostId
                                description
                            }
                        }
                        videos {
                            items {
                                description
                                hostId
                                registrations {
                                    member {
                                        fullname
                                        company {
                                            name
                                            urlname
                                            logoUrl(size: "300")
                                        }
                                    }
                                    summitRegistrationCategories {
                                        items {
                                            ...tag
                                        }
                                    }
                                }
                            }
                        }
                        meetingTimeRanges {
                            items {
                                startDate
                                endDate
                            }
                        }
                    }
                    participation {
                        primaryWabelContact {
                            status
                            email
                            fullname
                            idmember
                            mobile
                            logoUrl(size:"123")
                        }
                        administrativeContact {
                            status
                            email
                            fullname
                            idmember
                        }
                        participantNumber
                        allBadgesUrl
                        canSeeAgenda
                        canReceiveRatings
                        eventPackage{
                            id
                            priority
                        }
                    }
                    member {
                        idmember
                        fullname
                        firstname
                        name
                        email
                        mobile_event
                        status
                        eventOptinEmail
                        eventOptinSms
                        timezone
                        defaultDeliveryAddress {
                            firstName
                            lastName
                            phone
                            addressLine1
                            addressLine2
                            city
                            state
                            zipcode
                            country {
                                id
                                name
                            }
                        }
                        buyingScope: buyingScopeTags(tagTypeInternalName: "category") {
                            items {
                                id
                            }
                        }
                        company {
                            idcompany
                            name
                            company_type_machine_name
                            consultantCsm {
                                fullname
                                email
                                mobile
                                logoUrl(size:"123")
                            }
                            consultant {
                                email
                                name
                                fullname
                                logoUrl
                                mobile
                            }
                            allMembers {
                                idmember
                                fullname
                                email
                                status
                            }
                            summitsParticipation(eventCode: $eventCode) {
                                bannerUrl
                            }
                            registrationsForEvent(eventCode: $eventCode) {
                                idcertaineventregistration
                                member {
                                    idmember
                                    fullname
                                    email
                                    status
                                }
                                hotel
                                room
                                nightsDates
                                badgeUrl
                                noDiary
                            }
                        }
                    }
                    availabilities {
                        items {
                            availabilityStart,
                            availabilityEnd
                        }
                    }
                    privateMeetingPreferences(status:"selected") {
                        items {
                            company {
                                idcompany
                                name
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.tag}
        `,
        myRegistrationMeetingList: gql`
            query myRegistrationMeetingList($eventCode: String!) {
                myRegistration(eventCode: $eventCode) {
                    idcertaineventregistration
                    meetings(status: ["ok", "updated"]) {
                        items {
                            idmeeting
                            startDate
                            endDate
                            status
                            buyerPresent
                            supplierPresent
                            hasBeenRatedFromMyCompany
                            memberOppositeRegistration {
                                member {
                                    fullname
                                    jobtitle
                                    company {
                                        idcompany
                                        hasVideoMeetingRequestWithMe
                                        hasVideoMeetingWithMe
                                        name
                                        logoUrl(size:"123")
                                        urlname
                                        products(published: true, limit: 3) {
                                            logoUrl(size:"123")
                                        }
                                    }
                                }
                            }
                            myRatings {
                                items {
                                    idmeetingrating
                                    ratingInternalName
                                    rating
                                }
                            }
                            relation {
                                commentGiven
                            }
                            memberBuyer {
                                idmember
                                fullname
                            }
                            memberSupplier {
                                idmember
                                fullname
                            }
                            companyBuyer {
                                idcompany
                            }
                            companySupplier {
                                idcompany
                            }
                        }
                        count
                    }
                }
            }
        `,
        getMyRegistrationsByOrganizer: gql`
            query myRegistrationsByOrganizer($eventOrganizer: String!) {
                myRegistrationsByOrganizer(eventOrganizer: $eventOrganizer) {
                    items {
                        dateSelectionFinished
                        permissions
                        noDiary
                        dateLastAgendaCheck
                        certainevent {
                            eventCode
                            name
                            dates
                            startDate
                            endDate
                            location
                            agendaReady
                            selectionReady
                            catalogReady
                            ratingReady
                            ratingReadyForEssentialAndPremiumOnly
                            isFinished
                            isStarted
                            ispackaging
                            nameOrSummitName
                            backgroundImageUrl
                            squareImageUrl
                            meetingConfirmationsOpened
                            eventOrganizer {
                                ...fullEventOrganizer
                            }
                            eventType {
                                name
                            }
                        }
                        summitRegistrations {
                            items {
                                summit {
                                    name
                                    summitCode
                                }
                            }
                        }
                        participation {
                            meetingsNumber
                            participantNumber
                            canSeeAgenda
                            canReceiveRatings
                            memberRegistrations {
                                count
                            }
                            eventPackage {
                                id
                                name
                                priority
                            }
                        }
                        meetings(status: ["ok", "updated"]) {
                            items {
                                idmeeting
                                startDate
                                endDate
                                memberOppositeRegistration {
                                    member {
                                        company {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                        member {
                            businessMapping {
                                businessMappingPriorities {
                                    items {
                                        id
                                    }
                                }
                            }
                            company {
                                company_type_machine_name
                                description
                                lastUpdate
                                productCount(onlyPublished: true)
                                ecoPackProjects {
                                    count
                                }
                                businessMappingPriorities {
                                    count
                                }
                            }
                            lastWebRTCResult(onlyFinished: true) {
                                dateCreated
                            }
                        }
                    }
                }
            }
            ${EventOrganizerGraphql.fragments.full}
        `,
        myCompleteSelection: gql`
            query myCompleteSelection($eventCode: String!) {
                myRegistration(eventCode: $eventCode) {
                    selections: meetingPreferences(status: ["selected"]) {
                        items {
                            company {
                                idcompany
                                name,
                                logoUrl(size:"123"),
                                urlname,
                                citylocation {
                                    country {
                                        name
                                    }
                                }
                                products: orderedProductsOfCompanyByCategoriesOfBuyingScopesForSelectionTool(eventCode: $eventCode) {
                                    iduserproduct
                                    name
                                    logoUrl(size:"123"),
                                    category {
                                        name,
                                        parent {
                                            parent {
                                                name
                                                machineName
                                            }
                                        }
                                    }
                                }
                                activities: tags(tagTypeInternalName: "${TagTypeService.ACTIVITY_INTERNAL_NAME}") {
                                    items {
                                        ...fullTag
                                    }
                                }
                                priceRanges: companyProductsTags(tagTypeInternalName: "${TagTypeService.PRICE_RANGE_INTERNAL_NAME}") {
                                    items {
                                        ...fullTag
                                    }
                                }
                                categories: tagsByTypeInternalNamesAndDepthNameMatchingSummitTags(eventCode: $eventCode, typeInternalNames: ["${TagTypeService.CATEGORY_INTERNAL_NAME}"]) {
                                    items {
                                        ...fullTag
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,
    };

    static mutations = {
        editSupplierRegistration: gql`
            mutation editSupplierRegistration($idRegistration: Int!, $idMember: Int!) {
                editSupplierRegistration(idRegistration: $idRegistration, idMember: $idMember) {
                    idcertaineventregistration
                    member {
                        idmember
                        fullname
                        email
                    }
                    hotel
                    nightsDates
                    badgeUrl,
                    noDiary
                }
            }
        `,
        createRegistration: gql`
            mutation createRegistration($eventCode: String!, $idMember: Int!) {
                createRegistration(eventCode: $eventCode, idMember: $idMember) {
                    idcertaineventregistration
                    member {
                        idmember
                        fullname
                        email
                    }
                    hotel
                    nightsDates
                    badgeUrl,
                    noDiary
                }
            }
        `,
        sendAllBadgesByMail: gql`
            mutation sendAllBadgesByMail($idCompany: Int!, $eventCode: String!) {
                sendAllBadgesByMail(idcompany: $idCompany, eventCode: $eventCode) {
                    idcertaineventregistration
                }
            }
        `,
        removeRegistration: gql`
            mutation removeRegistration($idRegistration: Int!) {
                removeRegistration(idRegistration: $idRegistration) {
                    idcertaineventregistration
                }
            }
        `,

        updateParticipantDiaryForCompany: gql`
            mutation updateParticipantDiaryForCompany($idcertaineventregistration: Int!) {
                updateParticipantDiaryForCompany(idcertaineventregistration: $idcertaineventregistration) {
                    idcertaineventregistration
                    noDiary
                }
            }
        `,

        saveWabelEventRegistrations: gql`
            mutation saveWabelEventRegistrations($eventRegistrations: [WabelEventRegistrationInput!]!) {
                saveWabelEventRegistrations(eventRegistrations: $eventRegistrations)
            }
        `,

        wabelEventRegistrationsForEvents: gql`
            query wabelEventRegistrationsForEvents($eventCodes: [String!]!) {
                wabelEventRegistrationsForEvents(eventCodes: $eventCodes) {
                    timeline
                    certainEvent {
                        eventCode
                    }
                    summitRegistrations {
                        summit {
                            summitCode
                        }
                        productsLookingFor
                        tags {
                            ...fullTag
                        }
                    }
                    presenceDays {
                        date
                        slot
                    }
                    tags{
                        ...fullTag
                    }
                    continents{
                        id
                        name
                    }
                }
            }
            ${TagTypeGraphql.fragments.fullTag}
        `,

        updateDateLastAgendaCheck: gql`
            mutation updateDateLastAgendaCheck($idRegistration: Int!) {
                updateDateLastAgendaCheck(idRegistration: $idRegistration)
            }
        `,

        updateContactDetails: gql`
            mutation updateContactDetails(
                $idRegistration: Int!,
                $firstName: String,
                $lastName: String,
                $phone: String!,
                $notificationPreference: String!,
                $timezone: String!
            ) {
                updateContactDetails(
                    idRegistration:
                    $idRegistration,
                    firstName: $firstName,
                    lastName: $lastName,
                    phone: $phone,
                    notificationPreference: $notificationPreference,
                    timezone: $timezone
                ) {
                    idcertaineventregistration
                    selectionStartedDate
                }
            }
        `,
        updateWorkshopsPreferences: gql`
            mutation updateWorkshopsPreferences($idRegistration: Int!, $conferencePreferences: [EventWorkshopPreferenceInput!], $conferenceMeetingPreferences: [MeetingPreferenceInput!]) {
                updateWorkshopsPreferences(idRegistration: $idRegistration, conferencePreferences: $conferencePreferences, conferenceMeetingPreferences: $conferenceMeetingPreferences) {
                    eventWorkshopPreferences {
                        items {
                            eventworkshop {
                                ideventworkshop
                                title
                                expert {
                                    company {
                                        name
                                    }
                                }
                                startDate
                                endDate
                                conferenceType
                                idZoomWebinar
                                video {
                                    hostId
                                    description
                                }
                            }
                        }
                    }
                    privateMeetingPreferences(status:"selected") {
                        items {
                            company {
                                idcompany
                                name
                            }
                        }
                    }
                }
            }
        `,

        saveMultipleAvailabilities: gql`
            mutation saveMultipleAvailabilities($eventCode: String!, $registrationAvailabilities: [RegistrationAvailabilityInput!]!, $idregistration: Int!) {
                saveMultipleAvailabilities(eventCode: $eventCode, registrationAvailabilities: $registrationAvailabilities, idregistration: $idregistration) {
                    items {
                        availabilityStart,
                        availabilityEnd
                    }
                }
            }
        `,
        updateMeetingPreference: gql`
            mutation updateMeetingPreference($eventCode: String!, $idcompany: Int!, $type: String!, $removedReason: String, $customRemovedReason: String) {
                updateMeetingPreference(eventCode: $eventCode, idcompany: $idcompany, type: $type, removedReason: $removedReason, customRemovedReason: $customRemovedReason) {
                    status,
                }
            }`,
        updateMeetingPreferences: gql`
            mutation updateMeetingPreferences($idRegistration: Int!, $selections: [MeetingPreferenceInput!], $importedPreferencesToRemove: [MeetingPreferenceInput!]) {
                updateMeetingPreferences(idRegistration: $idRegistration, selections: $selections, importedPreferencesToRemove: $importedPreferencesToRemove) {
                    idcertaineventregistration
                }
            }`,

        askTeamScheduleMeeting: gql`
            mutation askTeamScheduleMeeting($eventCode: String!, $idcompany: Int!) {
                askTeamScheduleMeeting(eventCode: $eventCode, idcompany: $idcompany)
            }
        `,
    };
}
