import {WabelEntity} from "./wabel-entity.abstract";
import {Company} from "./company";
import {Member} from "./member";
import {Event} from "./event";
import {CompanyRelationship} from "./company_relationship";
import {EventRegistration} from "./event_registration";
import {MeetingRating} from "./meeting_rating";
import {PrivateMessageThread} from "./private_message_thread";
import {SampleRequest} from "./sample_request";

export class Meeting extends WabelEntity {
    constructor(obj: any) {
        super(obj);
        if (obj) {
            this.companyBuyer = obj.companyBuyer ? new Company(obj.companyBuyer) : null;
            this.companySupplier = obj.companySupplier ? new Company(obj.companySupplier) : null;
            this.memberBuyer = obj.memberBuyer ? new Member(obj.memberBuyer) : null;
            this.memberSupplier = obj.memberSupplier ? new Member(obj.memberSupplier) : null;
            this.event = obj.certainEvent ? new Event(obj.certainEvent) : null;
            this.relation = obj.relation ? new CompanyRelationship(obj.relation) : null;
            this.memberOppositeRegistration = obj.memberOppositeRegistration ? new EventRegistration(obj.memberOppositeRegistration) : null;
            this.myRatings = obj.myRatings && obj.myRatings.items && obj.myRatings.items.length > 0 ? obj.myRatings.items.map(rating => new MeetingRating(rating)) : [];
            this.othersRatings = obj.othersRatings && obj.othersRatings.items && obj.othersRatings.items.length > 0 ? obj.othersRatings.items.map(rating => new MeetingRating(rating)) : [];
            this.threadBetweenCompanySupplierAndMemberBuyer = obj.threadBetweenCompanySupplierAndMemberBuyer ? new PrivateMessageThread(obj.threadBetweenCompanySupplierAndMemberBuyer) : null;
            this.sampleRequest = obj.sampleRequest ? new SampleRequest(obj.sampleRequest) : null;
        }
    }


    idmeeting: number;
    companyBuyer: Company;
    companySupplier: Company;
    memberBuyer: Member;
    memberSupplier: Member;
    event: Event;
    status: string;
    matchingScore: number;
    relation: CompanyRelationship;
    startDate: string;
    endDate: string;
    memberOppositeRegistration: EventRegistration;
    buyerPresent: boolean;
    supplierPresent: boolean;
    myRatings: MeetingRating[];
    hasThreadBetweenCompanySupplierAndMemberBuyer: boolean;
    threadBetweenCompanySupplierAndMemberBuyer: PrivateMessageThread;
    meetingProvider: string;
    othersRatings: MeetingRating[];
    hasBeenRatedFromMyCompany: boolean;
    hasBeenSkippedForRating: boolean;
    linkToJoinByPhone: string;
    sampleRequest: SampleRequest;
    remainingTimeInSeconds: number;
    remainingTimeInSecondsBeforeMeeting: number;
    location: string;

    get formattedStartDateInDate(): Date {
        return new Date(this.startDate);
    }
    get formattedEndDateInDate(): Date {
        return new Date(this.endDate);
    }

    get id(): number {
        return this.idmeeting;
    }

    set id(id: number) {
        this.idmeeting = id;
    }

    get isFinished(): boolean {
        return new Date() > this.formattedEndDateInDate;
    }
}
